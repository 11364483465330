.video-responsive {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-responsive iframe {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
}
