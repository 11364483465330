.conteudo-container {
  display: flex;
  flex-direction: column;
  background-color: #fefcfb;
  align-items: center;
}

.formulario-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin-top: 100px;
  max-width: 1300px;
}

.formulario-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.formulario-perfil {
  display: flex;
  align-items: center;
}

.nome-perfil {
  font-size: 30px;
  font-family: "LifeMessy";
  letter-spacing: 0.04em;
  margin-left: 60px;
}

@media (max-width: 600px) {
  .formulario-header {
    flex-direction: column;
    gap: 35px;
    margin-bottom: 50px;
  }

  .formulario-perfil {
    flex-direction: column;
    gap: 10px;
  }

  .nome-perfil {
    margin: 0;
  }
}

.formulario-foto-perfil-container {
  width: 180px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  background: #d9d9d9;
  display: flex;
  justify-content: center;
  height: 180px;
  align-items: center;
}

.formulario-foto-perfil {
  object-fit: cover;
  width: 180px;
  height: 180px;
}

.trocar-foto-input-container {
  position: absolute;
  top: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 0, 0, 0.7);
}

.trocar-foto-input-label {
  font-family: "GalanoBold";
  text-transform: uppercase;
  color: white;
  text-align: center;
  line-height: 20px;
  width: 100%;
  height: 60px;
  padding: 8px 40px;
  font-size: 20px;
  letter-spacing: 0.04rem;
  cursor: pointer;
}

.trocar-foto-input {
  visibility: hidden;
  height: 0;
}

.voltar-botao {
  border: none;
  border-radius: 25px;
  color: #101010;
  background: white;
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
  width: fit-content;
  padding: 8px 14px;
  align-self: center;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.voltar-botao-texto {
  font-family: "GalanoMedium";
  font-size: 20px;
}

.voltar-botao-icone {
  width: 30px;
  height: auto;
  margin-left: 10px;
}

.secao-formulario-fieldset {
  width: 100%;
  height: auto;
  border: solid 1px rgba(16, 16, 16, 0.25);
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin-bottom: 80px;
  padding: 40px 71px 36px;
}

@media (max-width: 600px) {
  .secao-formulario-fieldset {
    padding: 40px 20px 26px;
  }
}

.secao-formulario-fieldset-legend {
  color: white;
  background-color: #dd2626;
  border-radius: 15px;
  padding: 12px 30px;
  text-transform: uppercase;
  font-family: "GalanoBold";
  letter-spacing: 0.04rem;
  font-size: 20px;
}

.secao-formulario-nome-text {
  font-family: "GalanoMedium";
  letter-spacing: 0.04rem;
  color: white;
}

.secao-formulario-aviso-container {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.secao-formulario-aviso-text {
  font-family: "GalanoBold";
}

.area-interesse-container {
  height: 50px;
  border: solid 2px rgba(16, 16, 16, 0.25);
  color: rgba(16, 16, 16, 0.25);
  border-radius: 15px;
  width: fit-content;
  padding: 10px 12px 10px 12px;
  font-family: "GalanoBold";
  letter-spacing: 0.04rem;
  text-transform: uppercase;
  cursor: pointer;
}

.area-interesse-selecionada {
  border: solid 2px #dd2626;
  color: #dd2626;
}

.area-interesse-sem-selecao {
  cursor: default;
}

.area-interesse-input-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px 20px;
}

.perfil-inputs-container {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(4, auto);
  gap: 30px 40px;
}

@media (max-width: 800px) {
  .perfil-inputs-container > div {
    grid-column: 1 / 5;
  }
}

.dropdown-selecionado-container {
  height: 50px;
  width: 100%;
  min-width: 160px;
}

Input {
  width: 100%;
  height: 50px;
  background: #fefcfb;
  border: 2px solid rgba(16, 16, 16, 0.25);
  border-radius: 15px;
  font-family: "GalanoMedium";
  font-size: 18px;
  padding: 4px 20px;
}

[readonly=""] {
  pointer-events: none;
}

.input-msg-erro {
  color: #dd2626;
  font-size: 14px;
}

.perfil-wrapper-label {
  position: relative;
  display: flex;
  width: fit-content;
  padding: 0 12px;
  margin-left: 24px;
  margin-bottom: -8px;
  background-color: #fefcfb;
  z-index: 2;
}

.perfil-text-label {
  font-family: "GalanoBold";
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #101010;
  text-align: center;
}

.nome-input-wrapper {
  grid-column: 1 / 5;
}

.matricula-input-wrapper {
  grid-column: 1 / 3;
}

.ocupacao-input-wrapper {
  grid-column: 4 / 5;
}

.org-input-wrapper {
  grid-column: 1 / 5;
  grid-row: 4 / 5;
}

.contato-inputs-container {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(2, auto);
  gap: 43px 0;
}

.email-input-wrapper {
  grid-column: 1 / 4;
}

.whatsapp-input-wrapper {
  grid-column: 1 / 2;
}

.social-inputs-wrappper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px 30px;
}

.rede-input-wrapper {
  align-self: start;
}

.usuario-input-wrapper {
  grid-column: 2 / 4;
}

.social-input-divider {
  grid-column: 1 / 4;
  color: rgba(16, 16, 16, 0.25);
  width: 95%;
  justify-self: center;
}

.remover-rede-botao {
  width: fit-content;
  border: none;
  color: #0500e5;
  background-color: transparent;
  padding: 5px;
  margin-top: -12px;
  margin-right: 40px;
  cursor: pointer;
  font-family: "GalanoMedium";
  font-size: 16px;
  justify-self: end;
  grid-column: 3 / 4;
}

.adicionar-rede-botao {
  grid-column: 1 / 4;
  justify-self: end;
  font-family: "GalanoMedium";
  text-transform: uppercase;
  cursor: pointer;
  font-size: 18px;
  border: none;
  background-color: transparent;
}

@media (max-width: 800px) {
  .social-inputs-wrappper {
    gap: 12px;
  }

  .social-inputs-wrappper > div,
  .social-inputs-wrappper > button {
    grid-column: 1 / 4;
  }

  .adicionar-rede-botao {
    margin-top: 16px;
  }
}

.salvar-botao {
  border: none;
  border-radius: 25px;
  background: white;
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
  width: fit-content;
  padding: 8px 14px;
  align-self: center;
  cursor: pointer;
  justify-self: center;
  font-family: "GalanoBold";
  text-transform: uppercase;
  font-size: 18px;
}

.desabilitado {
  cursor: default;
}
