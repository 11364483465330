.button-wrapper {
  border-width: 12px;
  border-style: solid;
  border-image-source: url(../images/btn-border.png);
  border-image-slice: 85;
  border-image-width: 1.9;
  border-image-repeat: stretch;
  border-image-outset: 0.19;
  display: flex;
  justify-content: center;
}

.button-icon {
  height: 24px;
}

.button-wrapper img {
  align-self: center;
  margin-right: 8px;
}

.button-red {
  border-radius: 14px;
  background-color: #dd2626;
  color: #fefcfb;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03em;
  font-family: "Open Sans", sans-serif;
}

.button-green {
  border-radius: 14px;
  background-color: #95b44e;
  color: #fefcfb;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.03em;
  font-family: "Open Sans", sans-serif;
}

.button-transparent {
  background-color: #fefcfb;
  color: #fefcfb;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03em;
  font-family: "Open Sans", sans-serif;
}

.button-big {
  border-radius: 14px;
  background-color: #dd2626;
  color: #fefcfb;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03em;
  font-family: "Open Sans", sans-serif;
}

@media only screen and (min-width: 680px) {
  .button-big {
    border-radius: 14px;
    background-color: #dd2626;
    color: #fefcfb;
    font-size: 38px;
    font-weight: 600;
    letter-spacing: 0.03em;
    font-family: "Open Sans", sans-serif;
  }
}
