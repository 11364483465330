.ativacao-container {
  display: flex;
  background-image: url("../images/bg-bambu.png");
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

.ativacao-content {
  display: flex;
  width: 100%;
  height: 100%;
}

.ativacao-section {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 120px;
  align-content: center;
  justify-content: center;
}

.ativacao-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fefcfb;
  max-width: 700px;
  min-height: 545px;
  border: 1px solid rgba(16, 16, 16, 0.25);
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 42px 100px;
  align-self: center;
}

@media only screen and (max-width: 720px) {
  .ativacao-wrapper {
    padding: 42px 20px;
    margin: 0 20px;
  }
}

.ativacao-wrapper-header {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
}

.ativacao-h1-header {
  font-family: "LifeMessy";
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.06em;
  color: #101010;
  text-align: center;
}

.ativacao-img-header {
  width: 400px;
}

@media only screen and (max-width: 720px) {
  .ativacao-img-header {
    width: 260px;
  }
}

.ativacao-form {
  display: flex;
  flex-direction: column;
  padding-top: 42px;
  max-width: 490px;
  height: 100%;
}

.ativacao-wrapper-label {
  display: flex;
  width: fit-content;
  padding: 0 12px;
  margin-left: 12px;
  margin-bottom: -8px;
  background-color: #fefcfb;
  z-index: 2;
}

.ativacao-text-label {
  font-family: "GalanoBold";
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: #101010;
}

.ativacao-form Input {
  max-width: 488px;
  height: 50px;
  background: #fefcfb;
  border: 2px solid rgba(16, 16, 16, 0.25);
  border-radius: 15px;
  font-family: "GalanoMedium";
  font-size: 18px;
  padding: 4px 20px;
  margin-bottom: 22px;
  z-index: 1;
}

.ativacao-form Input::placeholder {
  color: #d9d9d9;
}

.ativacao-button-login {
  display: flex;
  width: 200px;
  height: 50px;
  align-items: center;
  column-gap: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 28px;
  background-color: #dd2626;
  color: #fefcfb;
  font-family: "GalanoMedium";
  font-size: 20px;
  font-weight: 100;
  align-content: center;
  justify-content: center;
  align-self: center;
  margin-top: 22px;
  transition: 0.3s background-color ease-in-out;
}

.ativacao-button-login:hover {
  scale: 105%;
  transition: 0.3s;
}

.ativacao-button-login.disabled {
  pointer-events: none;
  background-color: #999;
  color: #ccc;
  opacity: 0.8;
  transition: 0.3s background-color ease-in-out;
}

.ativacao-rules-label:before {
  content: "X";
  position: absolute;
  top: 0;
  left: 0;
  transition: color 0.3s ease-in-out;
}

.ativacao-img-user {
  width: 22px;
}

.ativacao-rules-label {
  font-family: "GalanoMedium";
  font-size: 18px;
  color: #dd2626;
  padding-left: 16px;
  margin-bottom: 5px;
  position: relative;
}

.ativacao-rules-label:before {
  content: "X";
  position: absolute;
  top: 0;
  left: 0;
}

.ativacao-button-login:hover {
  transform: scale(1.05);
  transition: 0.3s transform ease-in-out;
}

.ativacao-img-header {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.ativacao-rules-label.minimo.valido,
.ativacao-rules-label.letra-maiuscula.valido,
.ativacao-rules-label.letra-minuscula.valido,
.ativacao-rules-label.caracter-especial.valido,
.ativacao-rules-label.numero.valido,
.ativacao-rules-label.confirmar-senha.valido {
  color: green;
}

.ativacao-rules-label.minimo.valido:before,
.ativacao-rules-label.letra-maiuscula.valido:before,
.ativacao-rules-label.letra-minuscula.valido:before,
.ativacao-rules-label.caracter-especial.valido:before,
.ativacao-rules-label.numero.valido:before,
.ativacao-rules-label.confirmar-senha.valido:before {
  content: "\2713";
}

@media only screen and (max-width: 560px) {
  .ativacao-container {
    height: auto;
  }

  .ativacao-section {
    padding-top: 150px;
    padding-bottom: 80px;
  }
}
