.input-with-label-container {
  display: flex;
  flex-direction: column;
  justify-items: start;
  height: 80px;
}

.input-wrapper-label {
  display: flex;
  width: fit-content;
  padding: 0 8px;
  margin-left: 14px;
  margin-bottom: -10px;
  background-color: #fefcfb;
  z-index: 1;
}

.input-text-label {
  font-family: "Galano Grotesque";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #101010;
  text-align: center;
}

.input-error {
  font-family: "Galano Grotesque";
  justify-self: end;
  color: #dd2626;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 16px;
}

.input-with-label Input {
  height: 40px;
  font-size: 16px;
}

.input-phone-with-label-container {
  display: flex;
  flex-direction: column;
  justify-items: start;
  height: 80px;
  width: 100%;
}

.input-phone-with-label {
  padding-left: 60px;
  display: flex;
  width: 100%;
}

.input-phone-country-selector {
  position: absolute !important;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 2px;

  align-self: center;
}

.input-password-with-label-container {
  display: flex;
  flex-direction: column;
  justify-items: start;
  height: 80px;
  width: 100%;

  ::-ms-reveal,
  ::-ms-clear {
    display: none;
  }
}

.flex {
  display: flex;
}

.input-password-with-label-toggle {
  background-color: transparent;
  border: none;
  margin-left: -40px;
}

.input-password-with-label {
  display: flex;
  width: 100%;
  padding-right: 40px;
}
