.passo-questoes-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: center;
}

.passo-questao-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.passo-questao-container > textarea {
  font-family: "GalanoMedium";
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
}

.passo-questao-enunciado {
  font-family: "GalanoMedium";
  font-size: 16px;
  margin-bottom: 8px;
  color: #010101;
  white-space: pre-wrap;
}

.passo-questao-resposta-salva-container {
  margin-left: 8px;
}

.passo-questao-sua-resposta {
  font-family: "GalanoMedium";
  font-size: 16px;
  margin-bottom: 8px;
  color: #444;
}

.passo-questao-resposta-salva {
  font-family: "GalanoMedium";
  font-size: 14px;
  margin-bottom: 8px;
  color: #444;
}

.passo-questoes-btn {
  font-family: "GalanoBold";
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  font-size: 18px;
  text-transform: uppercase;
  background-color: #fefcfb;
  border-radius: 24px;
  width: 120px;
  border: none;
  cursor: pointer;
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
}

.tarefa-feita {
  margin-left: 8px;
  margin-bottom: 10px;
  color: #228b22;
}
