.esqueci-a-senha-container {
  display: flex;
  background-image: url("../images/bg-bambu.png");
  background-size: cover;
  width: 100%;
  height: calc(100vh - 140px);
  /* 140px == altura do footer */
}

.esqueci-a-senha-form-margin {
  margin-top: 0.8rem;
}

.esqueci-a-senha-content {
  display: flex;
  width: 100%;
  height: 100%;
}

.esqueci-a-senha-section {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 120px;
  align-content: center;
  justify-content: center;
}

.esqueci-a-senha-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fefcfb;
  max-width: 500px;
  min-height: "fit-content";
  border: 1px solid rgba(16, 16, 16, 0.25);
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 42px 100px;
  align-self: center;
}

@media only screen and (max-width: 720px) {
  .esqueci-a-senha-wrapper {
    padding: 42px 20px;
    margin: 0 20px;
  }
}

.esqueci-a-senha-wrapper-header {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
}

.esqueci-a-senha-h1-header {
  font-family: "LifeMessy";
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.06em;
  color: #101010;
  text-align: center;
}

.esqueci-a-senha-img-header {
  width: 400px;
}

@media only screen and (max-width: 720px) {
  .esqueci-a-senha-img-header {
    width: 260px;
  }
}

.esqueci-a-senha-feedback {
  font-family: "GalanoBold";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.04em;
  text-align: center;
  margin: 0.8rem;
}

.esqueci-a-senha-feedback-success {
  color: #101010;
}

.esqueci-a-senha-feedback-error {
  color: #dd2626;
}

@media only screen and (max-width: 560px) {
  .esqueci-a-senha-section {
    padding-top: 150px;
    padding-bottom: 80px;
  }
}

.esqueci-a-senha-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
