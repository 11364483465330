.modal-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
  padding: 20px;
}

.modal {
  width: 50%;
  height: 80%;
  position: relative;
  border-radius: 16px;
  background-color: #fefcfb;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .modal-overlay {
    padding: 12px;
  }

  .modal {
    width: 70%;
    height: 70%;
  }

  .modal-fechar {
    top: 16px;
    right: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .modal {
    width: 100%;
    height: 100%;
    padding: 12px;
  }

  .modal-fechar {
    top: 12px;
    right: 12px;
  }

  .modal-body {
    padding: 12px 8px;
  }
}

.modal-fechar {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  cursor: pointer;
}

.modal-body {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px 16px;
  max-height: 100%;
  width: 100%;
  background-color: transparent;
  border: none;
}
