/* MAIN */

.home-container {
  zoom: 0.9;
  display: flex;
  background-color: #fefcfb;
  flex-direction: column;
  height: 100%;
}

.home-content {
  margin-top: 94px;
}

/* SECTION 1 */

.home-section1-container {
  display: flex;
  height: 720px;
  column-gap: 0px;
  padding: 60px;
  justify-content: space-around;
}

@media (max-width: 940px) {
  .home-section1-container {
    display: flex;
    flex-direction: column-reverse;
    column-gap: 0px;
    height: 500px;
    padding: 20px;
    align-items: center;
    justify-content: space-around;
  }
}

.home-section1-img-inicial {
  width: 722px;
  height: 608px;
}

@media (max-width: 1200px) {
  .home-section1-img-inicial {
    display: none;
  }
}

@media (max-width: 940px) {
  .home-section1-img-inicial {
    display: none;
  }
}

.home-section1-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-content: center;
  align-items: center;
  justify-items: center;
}

@media (max-width: 940px) {
  .home-section1-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 0px;
  }
}

.home-section1-img-logo {
  margin-top: 44px;
  width: 556px;
  height: 378px;
}

@media (max-width: 940px) {
  .home-section1-img-logo {
    margin-top: 30px;
    margin-right: 50px;
    width: 346px;
    height: 238px;
  }
}

.home-section1-wrapper-text {
  display: flex;
  width: 380px;
  align-self: flex-end;
}

@media (max-width: 940px) {
  .home-section1-wrapper-text {
    align-self: center;
  }
}

.home-section1-text-logo {
  font-family: "GalanoMedium";
  font-size: 18px;
  color: #101010;
  line-height: 30px;
  margin-top: 22px;
}

.home-section1-text-bold {
  font-family: "GalanoMedium";
  font-weight: bold;
}

.home-section1-text-grifado {
  font-family: "GalanoMedium";
  background-color: #dd2626;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 20px;
}

/* SECTION 2 */

.section2-container {
  position: relative;
}

.ic-fone {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(0px, 250px);
}

.section2-cards-container {
  position: relative;
  align-self: center;
}

.section2-cards {
  position: absolute;
  left: 0;
  top: 0;
  margin: 60px 35px;
  display: flex;
}

.section2-card {
  position: relative;
  margin-right: 25px;
}

.section5-card {
  position: relative;
  margin-right: 40px;
}

.desativado {
  display: none;
}

.borrado {
  scale: 102%;
  transition: 0.3s;
}

.section2-card button {
  position: absolute;
  top: 35%;
  left: 24%;
  width: 160px;
  cursor: pointer;
  height: 50px;
  border-radius: 100px;
  font-size: 18px;
  font-family: GalanoMedium;
  font-weight: bold;
  border: none;
}

.section3-card button {
  position: absolute;
  top: 30%;
  left: 24%;
  width: 160px;
  cursor: pointer;
  height: 50px;
  border-radius: 100px;
  font-size: 18px;
  font-family: GalanoMedium;
  font-weight: bold;
  border: none;
}

.section5-card button {
  position: absolute;
  top: 35%;
  left: 24%;
  width: 160px;
  cursor: pointer;
  height: 50px;
  border-radius: 100px;
  font-size: 18px;
  font-family: GalanoMedium;
  font-weight: bold;
  border: none;
}

.section2-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 90%;
  margin: 75px 80px 0px 80px;
  left: 0;
  top: 0;
}

.section3-container {
  padding: 140px 150px 0px;
}

.section3-cards {
  display: flex;
  justify-content: space-between;
}

.section3-card {
  position: relative;
  width: 300px;
  margin-right: 15px;
}

.section3-card > img {
  margin-bottom: 40px;
}

.section4-container {
  padding: 120px 150px 0px;
}

.section4-content {
  display: flex;
  flex-direction: column;
}

.section5-container {
  padding: 120px 150px 0px;
}

.section5-cards {
  display: flex;
  justify-content: flex-start;
}

.section6-container {
  padding: 120px 150px;
}

.section6-perguntas {
  margin-left: 50px;
}

.bullet {
  font-weight: bold;
}

.icon-perguntas {
  margin-right: 15px;
}

.question {
  cursor: pointer;
}

.qa {
  margin-bottom: 30px;
}

.answer {
  margin-left: 40px;
}

.madeirite-bg-img {
  width: 100%;
  height: 750px;
}

.section6-content {
  display: flex;
  justify-content: space-between;
}

.dados-img {
  margin-top: 102px;
}

footer {
  background-color: #101010;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.texto-footer {
  color: #fefcfb;
  font-family: "GalanoMedium";
  font-weight: bold;
}

h2 {
  color: #101010;
  font-family: "LifeMessy";
  font-size: 30px;
  letter-spacing: 4px;
  font-weight: bold;
  margin-bottom: 50px;
}

h3 {
  color: #101010;
  font-size: 18px;
  font-family: "GalanoMedium";
  font-weight: normal;
  line-height: 30px;
}

p {
  font-size: 10px;
  font-family: "GalanoMedium";
}

.home-h2 {
  color: #101010;
  font-family: "LifeMessy";
  font-size: 30px;
  letter-spacing: 4px;
  font-weight: bold;
  margin-bottom: 50px;
}

@media (max-width: 1024px) {
  .section2-content h2 {
    font-size: 16px;
    margin-left: 24px;
  }

  .section2-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 31px 0px 0px 0px;
  }

  .tnt-bg-img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }

  .section2-cards-container {
    overflow: hidden;
  }

  .section2-cards {
    margin: 60px 0 60px 24px;
    overflow: scroll;
    max-width: 100%;
  }

  .section2-card img {
    width: 260px;
  }

  .section2-card button {
    left: 29%;
    font-size: 13px;
    border: none;
    width: fit-content;
    height: fit-content;
    padding: 10px;
    font-weight: bold;
    border: none;
  }

  .ic-fone {
    display: none;
  }

  .section3-container {
    padding: 30px 0 0 0;
  }

  .section3-content h2 {
    font-size: 16px;
    letter-spacing: 3px;
    text-align: left;
    margin-left: 24px;
  }

  .section3-cards {
    overflow: scroll;
    gap: 16px;
    margin-left: 24px;
  }

  .section3-card {
    width: 260px;
    margin-right: 15px;
  }

  .section3-card > img {
    margin-bottom: 24px;
    width: 260px;
    margin-right: 15px;
  }

  .section3-card h3 {
    font-size: 13px;
    text-align: left;
  }

  .section4-container {
    padding: 30px 22px 0 22px;
  }

  .section4-content h2 {
    font-size: 16px;
    letter-spacing: 3px;
    text-align: left;
  }

  .section4-content h3 {
    font-size: 13px;
  }

  .dados-img {
    margin-top: 55px;
    width: 100%;
    align-self: center;
  }

  .section5-container {
    padding: 55px 22px 0 22px;
  }

  .section5-content h2 {
    font-size: 16px;
    letter-spacing: 3px;
    text-align: left;
  }

  .section5-cards {
    overflow: scroll;
  }

  .section5-cards img {
    width: 260px;
    height: 260px;
  }

  .section6-container {
    padding: 49px 22px 0 22px;
  }

  .section6-container h2 {
    font-size: 16px;
    letter-spacing: 3px;
    text-align: left;
  }

  .section6-img {
    display: none;
  }

  .section6-perguntas {
    margin-left: 0px;
  }

  .qa {
    margin-bottom: 23px;
  }

  .icon-perguntas {
    margin-right: 13px;
    width: 12px;
    height: 26px;
    object-fit: contain;
  }

  .question {
    font-size: 13px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .answer {
    margin-left: 25px;
    font-size: 13px;
  }
}

.rodape-links {
  border: solid;
  padding: 50px;
  display: flex;
  justify-content: space-around;
}

.rodape-links:first-of-type {
  border-radius: 15px 15px 0px 0px;
  border-bottom: none;
}

.rodape-links text {
  font-family: GalanoMedium;
  font-size: 30px;
  font-weight: bold;
  margin-right: 20px;
}

.ic-link {
  width: 50px;
  height: 50px;
  margin: 0px 10px;
  cursor: pointer;
}

.links {
  display: flex;
  align-items: center;
}

.home-button-zap {
  display: flex;
  height: 48px;
  align-items: center;
  column-gap: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 28px;
  box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: #95b44e;
  color: #fefcfb;
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: 100;
  align-self: center;
  margin-left: auto;
}

.home-button-zap:hover {
  scale: 105%;
  transition: 0.3s;
}

@media (max-width: 940px) {
  .ic-link {
    width: 40px;
    height: 40px;
    margin: 0px 5px;
  }

  .rodape-links {
    padding: 20px;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }

  .rodape-links:first-of-type {
    border-radius: 15px 15px 0px 0px;
    border-bottom: none;
  }

  .rodape-links text {
    display: none;
  }

  .home-button-zap {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    margin-left: 0;
  }

  .home-button-zap:hover {
    scale: 105%;
    transition: 0.3s;
  }
}
