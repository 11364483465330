.acordeon-container {
  padding: 0px;
  border-radius: 12px;
  border: 2px solid #fefcfb;
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.25);
  background-color: #101010;
  display: flex;
  flex-direction: column;
}

.acordeon-container > li + li {
  border-top: 2px solid #fefcfb;
}

@media only screen and (max-width: 640px) {
  .acordeon-container {
    display: none;
  }
}

.acordeon-item {
  width: 260px;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: center;
}

@media only screen and (max-width: 1080px) {
  .acordeon-item {
    width: 200px;
  }
}

.acordeon-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.acordeon-text-main {
  font-family: "LifeMessy";
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.12em;
  color: #fefcfb;
}

.acordeon-text-main-off {
  font-family: "LifeMessy";
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.12em;
  color: rgba(254, 252, 251, 0.25);
}

.acordeon-img-seta-baixo {
  cursor: pointer;
  width: 26px;
}

.acordeon-img-seta-baixo-off {
  display: none;
}

.acordeon-img-seta-cima {
  cursor: pointer;
  width: 26px;
}

.acordeon-wrapper-child-ativo {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.acordeon-wrapper-child {
  display: none;
}

.acordeon-text-child {
  font-family: "LifeMessy";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.12em;
  color: #fefcfb;
  width: 292px;
  padding: 10px;
}

.acordeon-wrapper-checklist {
  display: flex;
  width: 292px;
  height: 50px;
  border-image-source: url("../images/bd-check.png");
  border-width: 5px;
  border-style: solid;
  border-image-slice: 40;
  border-image-width: 2;
  margin: auto;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}

.acordeon-wrapper-checklist-off {
  display: none;
}

.acordeon-text-checklist {
  font-family: "GalanoMedium";
  font-size: 18px;
  letter-spacing: 0.04em;
  color: #fefcfb;
}

.acordeon-link-checklist {
  cursor: pointer;
  font-family: "GalanoMedium";
  font-size: 18px;
  letter-spacing: 0.04em;
  color: #fefcfb;
}

.acordeon-wrapper-checkbox {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  align-items: center;
}

.acordeon-img-check-ativo {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 25px;
  z-index: 2;
}

.acordeon-img-check {
  display: none;
}

.acordeon-img-checkbox {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 30px;
  height: 30px;
  z-index: 1;
}

.acordeon-wrapper-material {
  display: flex;
  width: 292px;
  height: 50px;
  border-image-source: url("../images/bd-check.png");
  border-width: 5px;
  border-style: solid;
  border-image-slice: 40;
  border-image-width: 2;
  margin: auto;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}

.acordeon-text-material {
  cursor: pointer;
  font-family: "GalanoMedium";
  font-size: 18px;
  letter-spacing: 0.04em;
  color: #fefcfb;
}
