.curso-container {
  display: flex;
  flex-direction: column;
  background-color: #fefcfb;
  width: 100%;
}

/* Banner  */

.curso-banner-background {
  display: flex;
  height: 360px;
  width: 100%;
  background-size: cover;
  /* Esse tamanho é a altura do header */
  margin-top: 90px;
  padding: 0 16px 0;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 15px 15px;
}

.curso-banner-overlay {
  display: flex;
  justify-content: space-between;
  flex: 1;
  max-width: 1300px;
  height: 320px;
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 30px 8px;
}

.curso-banner-esquerda {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 16px;
  padding-right: 16px;
}

.curso-banner-container-titulo {
  margin-bottom: 16px;
}

.curso-banner-container-titulo h1 {
  font-family: "GalanoBold";
  font-size: 32px;
  line-height: 1.15em;
  letter-spacing: 0.1em;
  color: #fefcfb;
  margin-bottom: 8px;
  text-align: left;
}

.curso-banner-container-titulo h2 {
  font-family: "GalanoBold";
  font-size: 24px;
  line-height: 1.15em;
  letter-spacing: 0.1em;
  margin-bottom: 24px;
  text-align: left;
}

.curso-banner-formadores {
  font-family: "GalanoMedium";
  font-size: 16px;
  color: #fefcfb;
  justify-self: flex-start;
}

.curso-banner-esquerda h3 {
  font-family: "GalanoMedium";
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 0.04em;
  color: #fefcfb;
  margin-bottom: 20px;
}

.curso-banner-esquerda h3 strong {
  font-family: "GalanoBold";
}

@media only screen and (max-width: 1024px) {
  .curso-banner-esquerda {
    padding-left: 8px;
    padding-right: 16px;
  }

  .curso-banner-container-titulo h1 {
    font-size: 24px;
    margin-bottom: 4px;
  }

  .curso-banner-container-titulo h2 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .curso-banner-esquerda h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .curso-banner-background {
    padding: 0 8px 0;
  }

  .curso-banner-container-titulo h2 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .curso-banner-esquerda h3 {
    font-size: 16px;
  }
}

.curso-button-matricula {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 42px;
  cursor: pointer;
  border: none;
  border-radius: 28px;
  box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: #101010;
  color: #fefcfb;
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: 100;
}

.curso-button-matricula:hover {
  scale: 105%;
  transition: 0.3s;
}

.curso-button-matricula-disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 42px;
  cursor: pointer;
  border: none;
  border-radius: 28px;
  box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: #101010;
  color: #fefcfb;
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: 100;
  cursor: default;
}

.curso-banner-direita {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .curso-banner-direita {
    display: none;
  }
}

/* Corpo */

.curso-corpo {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 56px 16px;
}

.curso-corpo-colunas {
  flex: 1;
  max-width: 1300px;
  display: flex;
  gap: 52px;
}

@media only screen and (max-width: 1024px) {
  .curso-corpo {
    padding: 32px 16px 16px;
  }
  .curso-corpo-colunas {
    display: flex;
    flex-direction: column-reverse;
    gap: 52px;
    width: 100%;
  }
}

.curso-corpo-coluna-esquerda {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 52px;
}

.curso-corpo-coluna-direita {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 360px;
}

@media only screen and (max-width: 1024px) {
  .curso-corpo-coluna-direita {
    align-items: center;
    width: 100%;
  }
}

.curso-secao-informacao {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.curso-secao-informacao-titulo {
  margin-left: 28px;
  font-family: "LifeMessy";
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.06em;
  color: #101010;
}

@media only screen and (max-width: 1024px) {
  .curso-secao-informacao-titulo {
    font-size: 24px;
  }
}

.curso-section2-img-rabisco1 {
  width: 370px;
}

@media only screen and (max-width: 1024px) {
  .curso-section2-img-rabisco1 {
    width: 260px;
  }
}

.curso-secao-informacao-conteudo {
  font-family: "GalanoMedium";
  font-size: 18px;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  color: #101010;
  padding-left: 54px;
  padding-top: 30px;
}

.curso-secao-informacao-video-vertical {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.curso-secao-informacao-video-horizontal {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media only screen and (max-width: 1024px) {
  .curso-secao-informacao-video-vertical {
    flex-direction: column;
  }
}

.curso-sobre-setor-ou-coletivo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "GalanoMedium";
  font-size: 18px;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  color: #101010;
}

@media only screen and (max-width: 1024px) {
  .curso-secao-informacao-conteudo {
    padding: 24px 30px;
  }
}

.curso-section2-wrapper2 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.curso-section2-wrapper-hl {
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.curso-section2-img-rabisco2 {
  width: 280px;
  height: 56px;
}

@media only screen and (max-width: 1024px) {
  .curso-section2-img-rabisco2 {
    width: 150px;
    height: 32px;
  }
}

.curso-wrapper-aulas {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  margin-left: 54px;
}

@media only screen and (max-width: 1024px) {
  .curso-wrapper-aulas {
    padding: 30px;
    margin: 0px;
  }
}

.curso-wrapper-aula {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #dd2626;
  min-height: 31px;
  padding: 5px 0px;
}

.curso-section2-text-numero {
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: bolder;
  letter-spacing: 0.08em;
  color: #101010;
  width: 110px;
}

.curso-section2-text-aula {
  flex: 1;
  font-family: "GalanoMedium";
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #101010;
}

.curso-formadores-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 22px;
}

.curso-section2-wrapper-ficha {
  display: flex;
}

.curso-section2-wrapper-formador {
  display: grid;
  width: 100px;
  height: 100px;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  align-content: center;
  justify-content: center;
  margin-right: 20px;
  margin-left: 56px;
}

@media only screen and (max-width: 1024px) {
  .curso-section2-wrapper-formador {
    margin-left: 20px;
  }
}

.curso-section2-img-formador {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 94px;
  height: 94px;
  align-self: center;
  justify-self: center;
}

.curso-section2-img-rabisco3 {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 100px;
  height: 100px;
}

.curso-section2-text-nome {
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #101010;
}

.curso-section2-text-cv {
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: 100;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #101010;
}

@media only screen and (max-width: 1024px) {
  .curso-section2-text-cv {
    margin: 0 20px;
  }
}

.curso-section2-text-inscritos {
  font-family: "LifeMessy";
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  letter-spacing: 0.04em;
  color: #101010;
}

.curso-section2-wrapper-infos {
  display: grid;
  grid-template-columns: 1.2fr 2.2fr 4fr;
  width: 360px;
}

.curso-section2-wrapper-info {
  display: flex;
  align-items: center;
}

.curso-section2-img-categ {
  width: 42px;
  height: 32px;
  align-self: center;
  justify-self: center;
}

.curso-section2-img-data {
  width: 30px;
  height: 28px;
  align-self: center;
  justify-self: center;
}

.curso-section2-img-forma {
  width: 36px;
  height: 24px;
  align-self: center;
  justify-self: center;
}

.curso-section2-img-duracao {
  width: 24px;
  height: 24px;
  align-self: center;
  justify-self: center;
}

.curso-section2-obs {
  font-family: "GalanoMedium";
  font-size: 14px;
  color: #101010;
  margin-top: 8px;
}

.curso-section2-text-tipo {
  font-family: "GalanoMedium";
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.04em;
  color: #101010;
  border-bottom: 1px solid #dd2626;
}

.curso-section2-text-dado {
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 0.04em;
  color: #101010;
  border-bottom: 1px solid #dd2626;
  text-align: right;
}

.curso-section2-button-voltar {
  display: flex;
  width: 120px;
  height: 48px;
  align-items: center;
  column-gap: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 28px;
  box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: #101010;
  color: #fefcfb;
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: 100;
  justify-content: center;
}

.curso-section2-button-voltar:hover {
  scale: 105%;
  transition: 0.3s;
}

.curso-banner-matricula {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  row-gap: 20px;

  border-radius: 15px;
  background-color: #fefcfb;
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
  padding: 15px;
  z-index: 1000;
}

.curso-wrapper-curso {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.curso-wrapper-just {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.curso-wrapper-text {
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: bold;
}

.curso-wrapper-textarea {
  display: flex;
  height: 50%;
  border: solid #fefcfb;
  font-family: "GalanoMedium";
  font-size: 20px;
  padding: 24px;
  margin-bottom: 10px;
  border: 1px solid #101010;
  border-radius: 15px;
  vertical-align: top;
}

.curso-modal-matricula-buttons {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  margin: 20px 10px;
  justify-content: ;
}
