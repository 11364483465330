.sidebar-container {
  position: fixed;
  background-color: #fefcfb;
  width: 340px;
  height: fit-content;
  top: 0px;
  right: 0px;
  border-radius: 15px;
  box-shadow: 0px 12px 8px rgba(4, 6, 6, 0.3);
}

.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 26px;
  overflow-y: auto;
  max-height: 99vh;
}

.sidebar-wrapper-bloco {
  display: flex;
  flex-direction: column;
}

.sidebar-text-main {
  font-family: "GalanoMedium";
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #101010;
  margin-bottom: 8px;
}

.sidebar-text-main-link {
  font-family: "GalanoMedium";
  font-size: 15px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: #101010;
}

.sidebar-text-main-link:hover {
  color: #dd2626;
  font-weight: bold;
  transition: 0.3s;
}

.sidebar-text-child {
  cursor: pointer;
  font-family: "GalanoMedium";
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: 0.04em;
  color: rgba(16, 16, 16, 0.7);
  margin-left: 10px;
  margin-bottom: 6px;
}

.sidebar-text-child:hover {
  color: #dd2626;
  font-weight: bold;
  transition: 0.3s;
}

.sidebar-wrapper-close {
  cursor: pointer;
  display: flex;
  justify-content: end;
}

.sidebar-button-login {
  display: flex;
  width: 200px;
  height: 50px;
  align-items: center;
  column-gap: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 28px;
  background-color: #101010;
  color: #fefcfb;
  font-family: "GalanoMedium";
  font-size: 20px;
  font-weight: 100;
  margin-top: 20px;
  align-content: center;
  justify-content: center;
  align-self: center;
}

.sidebar-button-login:hover {
  scale: 105%;
  transition: 0.3s;
}
