/* --------------------- CONTAINER --------------------- */

.aluno-container {
  zoom: 0.9;
  display: flex;
  flex-direction: column;
  background-color: #fefcfb;
  width: 100%;
}

.aluno-content {
  display: flex;
  width: 100%;
}

/* --------------------- SEÇÃO 1 --------------------- */

.aluno-section1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  width: 100%;
}

.aluno-section1-container {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: flex;
  flex-direction: column;
  row-gap: 46px;
  width: 100%;
  padding: 140px 150px 62px 150px;
  z-index: 2;
}

@media only screen and (max-width: 1200px) {
  .aluno-section1-container {
    padding: 140px 20px;
  }
}

.aluno-section1-wrapper1 {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .aluno-section1-wrapper1 {
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    text-align: center;
  }
}

.aluno-section1-img-user {
  width: 100px;
  height: 100px;
}

.aluno-section1-h1-bemvindo {
  margin-left: 20px;
  font-family: "LifeMessy";
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.06em;
  color: #101010;
}

.aluno-section1-h1-bemvindo-red {
  font-family: "LifeMessy";
  font-size: 30px;
  font-weight: 400;
  color: #dd2626;
}

.aluno-button-edit {
  display: flex;
  height: 48px;
  align-items: center;
  column-gap: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 28px;
  box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: #fefcfb;
  color: #101010;
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: 100;
  align-self: center;
  margin-left: auto;
}

@media only screen and (max-width: 1200px) {
  .aluno-button-edit {
    margin: auto 0;
  }
}

.aluno-button-edit:hover {
  scale: 105%;
  transition: 0.3s;
}

.aluno-section1-wrapper2 {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  width: 100%;
}

.aluno-section1-h1-titulo {
  font-family: "LifeMessy";
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.06em;
  color: #101010;
  scroll-margin: 150px;
}

.aluno-section1-button {
  display: flex;
  height: 48px;
  align-items: center;
  gap: 4px;
  padding: 20px;
  cursor: pointer;
  border: none;
  border-radius: 24px;
  box-shadow: 4px 6px 6px 0px rgba(0, 0, 0, 0.3);
  font-family: "GalanoMedium";
  font-size: 16px;
  background-color: #dd2626;
  color: #fefcfb;
  align-self: center;
}

.aluno-section1-wrapper-cursos {
  display: flex;
  column-gap: 30px;
  justify-content: space-around;
}

.aluno-section1-text-ativo {
  background: url("../images/contorno2.svg") transparent center no-repeat;
  background-size: contain;
  border: none;
  color: #dd2626;
  cursor: pointer;
  display: flex;
  font-family: "GalanoMedium";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.04em;
  padding: 12px;
}

.aluno-section1-text-cursos {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  font-family: "GalanoMedium";
  font-size: 24px;
  font-weight: 100;
  letter-spacing: 0.04em;
  padding: 12px;
}

@media only screen and (max-width: 768px) {
  .aluno-section1-wrapper-cursos {
    column-gap: 0;
  }

  .aluno-section1-text-ativo {
    font-size: 15px;
  }

  .aluno-section1-text-cursos {
    font-size: 15px;
    white-space: nowrap;
  }
}

.aluno-section1-wrapper-cards {
  display: flex;
  height: 230px;
  column-gap: 30px;
}

@media only screen and (max-width: 1200px) {
  .aluno-section1-wrapper-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
  }
}

.aluno-section1-wrapper-inativo {
  display: none;
}

.aluno-section1-card {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 230px;
  border-radius: 15px;
  box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
}

.aluno-section1-card:hover {
  scale: 105%;
  transition: 0.3s;
}

.aluno-section1-card-up {
  display: flex;
  background-image: url("../images/card-curso1.png");
  background-position-x: -2px;
  background-position-y: 1px;
  background-size: 102%;
  width: 300px;
  height: 130px;
  border-radius: 15px 15px 0 0;
  align-items: center;
  justify-content: center;
}

.aluno-button-continuar {
  display: flex;
  height: 48px;
  align-items: center;
  column-gap: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 28px;
  box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: #101010;
  color: #fefcfb;
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: 100;
}

.aluno-button-continuar-off {
  display: none;
}

.aluno-section1-card-down {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background-color: #fefcfb;
  width: 300px;
  height: 100px;
  border-radius: 0 0 15px 15px;
  align-items: center;
  justify-content: center;
}

.aluno-section1-progbar {
  border-image-source: url("../images/bd-prog.png");
  border-width: 12px;
  border-style: solid;
  border-image-slice: 40;
  border-image-width: 2;
  background-image: url("../images/bg-prog.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 115% 80px;
  background-position: -10px;
  width: 220px;
  height: 28px;
}

.aluno-section1-card-text {
  font-family: "GalanoMedium";
  font-size: 16px;
  font-weight: 100;
}

.aluno-section1-card-text-red {
  font-family: "GalanoMedium";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.04em;
  color: #dd2626;
}

.aluno-section1-wrapper-download {
  display: flex;
  column-gap: 20px;
  align-items: center;
  cursor: pointer;
}

.aluno-section1-img-download {
  width: 58px;
  height: 50px;
}

.aluno-section1-wrapper-nenhum {
  font-family: "GalanoMedium";
  font-size: 16px;
  font-weight: 100;
  border-radius: 28px;
  box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: #fefcfb;
  padding: 10px 20px;
  align-self: center;
}

.aluno-section1-wrapper3 {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  width: 100%;
  margin-top: 80px;
}

.aluno-section1-wrapper3-row {
  display: flex;
  align-items: center;
  gap: 20px;
}

.aluno-section1-bg {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 730px;
  justify-self: right;
  align-self: flex-end;
  z-index: 1;
}

@media only screen and (max-width: 1200px) {
  .aluno-section1-bg {
    display: none;
  }
}

/* --------------------- SEÇÃO 2 --------------------- */

.aluno-section2 {
  display: flex;
  width: 100%;
}

.aluno-section2-container {
  display: flex;
  background-image: url("../images/bg-papel.png");
  background-size: cover;
  flex-direction: column;
  width: 100%;
  padding: 70px 150px;
}

@media only screen and (max-width: 1200px) {
  .aluno-section2-container {
    padding: 140px 20px;
  }
}

.aluno-section-wrapper {
  display: flex;
}

.aluno-section-text-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.aluno-section2-h1-titulo {
  font-family: "LifeMessy";
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.06em;
  color: #101010;
}

.aluno-section2-h2-titulo {
  font-family: "LifeMessy";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.06em;
  color: #101010;
}

.aluno-section2-carrossel-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 70px 0;

  justify-content: space-around;
}

@media only screen and (max-width: 700px) {
  .aluno-section2-carrossel-wrapper {
    padding: 30px 0;
    flex-direction: column-reverse;
  }
}

.aluno-section2-video-wrapper {
  min-width: 340px;
  width: 340px;
  min-height: 620px;
  height: 620px;
}

.aluno-section2-setores-wrapper {
  align-self: center;
  justify-items: center;
  align-items: center;
  width: 900px;
  margin: 20px;
}

@media only screen and (max-width: 700px) {
  .aluno-section2-setores-wrapper {
    width: 100%;
    margin: 40px;
  }
}

.aluno-carrossel-main {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(3, 1fr);
  row-gap: 70px;
  column-gap: 70px;

  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media only screen and (max-width: 700px) {
  .aluno-carrossel-main {
    grid-template-rows: repeat(1, 1fr);
  }
}

.aluno-carrossel-main::-webkit-scrollbar {
  display: none;
}

.aluno-carrossel-item {
  display: flex;
  width: 240px;
  height: 100px;
  background-color: #dd2626;
  border-radius: 15px;
  align-items: center;
  justify-content: center;

  font-family: "GalanoMedium";
  font-size: 22px;
  font-weight: bold;
  color: #fefcfb;

  cursor: pointer;
}

.aluno-carrossel-item:hover {
  background-color: #101010;
  transition: 0.3s;
}

/* --------------------- SEÇÃO 3 --------------------- */

.aluno-cursos-modulo2 {
  display: flex;
  background-image: url("../images/bg-papel.png");
  background-size: cover;
  flex-direction: column;
  width: 100%;
  padding: 40px 5%;
}

@media only screen and (max-width: 1024px) {
  .aluno-cursos-modulo2-titulos {
    padding: 0 16px;
  }
}

.aluno-cursos-modulo2-titulos {
  padding: 0 32px;
}

.aluno-cursos-modulo2-titulos h1 {
  font-family: "LifeMessy";
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.06em;
  color: #101010;
  margin-bottom: 10px;
}

.aluno-cursos-modulo2-titulos h2 {
  font-family: "LifeMessy";
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.06em;
  color: #101010;
  margin-bottom: 10px;
}

.aluno-cursos-modulo2-carrossel-wrapper {
  width: 100%;
  aspect-ratio: 3.3;
  background-size: cover;
  background-image: url("../images/bg-moldura1.png");
  padding: 30px;
}

@media only screen and (max-width: 1024px) {
  .aluno-cursos-modulo2-carrossel-wrapper {
    padding: 16px;
  }
}
.carousel-item-padding-32-px {
  padding: 0 6px;
}

.aluno-section3-card {
  position: relative;
  padding: 20px 10px;
}

.aluno-section3-card:hover {
  scale: 105%;
  transition: 0.3s;
}

.aluno-section3-card-img {
  width: 100%;
  border-radius: 15px;
  box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.2);
}

.aluno-section3-card-button {
  display: none;
  position: absolute;
  margin: 0;

  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  cursor: pointer;
  border: none;
  border-radius: 28px;
  box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: #fefcfb;

  height: 32px;
  width: 128px;

  color: #101010;
  font-family: "GalanoMedium";
  font-size: 16px;
  font-weight: bold;
}

.aluno-section3-card:hover .aluno-section3-card-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
