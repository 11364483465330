.informacao-extra-header {
  font-size: 24px;
  margin-bottom: 8px;
}

.informacao-extra-p {
  font-size: 16px;
  align-self: flex-start;
  margin-top: 4px;
  margin-left: 8px;
  font-family: "GalanoMedium";
}

.informacao-extra-form {
  padding: 4px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.informacao-extra-label {
  margin: 4px 8px;
  align-self: flex-start;
  text-align: left;
  font-family: "Galano Grotesque";
  font-size: 16px;
}

.informacao-extra-form input {
  height: 40px;
  font-family: "Galano Grotesque";
  font-size: 16px;
  padding: 4px 12px;
  color: #333;
}

.mensagem-atencao {
  margin-left: 8px;
  margin-bottom: 12px;
  color: #333;
  font-family: "Galano Grotesque";
  align-self: start;
}

.row-wrapper {
  margin-top: 8px;
  margin-bottom: 4px;
  width: 100%;
  display: flex;
  gap: 8px;
}

@media only screen and (max-width: 768px) {
  .row-wrapper {
    flex-direction: column;
  }
}

.dropdown-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.informacao-extra-form .input-error {
  align-self: flex-start;
}

.sucesso-header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
}

h3 {
  margin-bottom: 30px;
}

.sucesso-header {
  margin-bottom: 4px;
}

.sublinhado-matricula {
  width: 400px;
}

@media only screen and (max-width: 768px) {
  .sucesso-header {
    font-size: 20px;
  }

  .sublinhado-matricula {
    width: 280px;
  }
}

.sucesso-modal-body {
  align-self: center;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
