.dropdown-container {
  position: relative;
}

.dropdown-selecionado-container {
  display: flex;
  width: 270px;
  height: fit-content;
  border: solid 2px rgba(16, 16, 16, 0.25);
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  padding: 18px 12px;
  cursor: pointer;
}

.dropdown-selecionado-text {
  font-family: "GalanoBold";
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.04em;
  color: #dd2626;
}

.placeholder-text {
  color: rgba(16, 16, 16, 0.25);
}

.dropdown-seta {
  width: 20px;
  height: auto;
  filter: invert(1);
}

.dropdown-opcoes-container {
  display: flex;
  flex-direction: column;
  width: 270px;
  max-height: 400px;
  border: solid 2px rgba(16, 16, 16, 0.25);
  border-radius: 15px;
  padding: 8px 12px;
  position: absolute;
  background: #fefcfb;
  z-index: 3;
  overflow: auto;
}

.dropdown-opcoes-text {
  font-family: "GalanoBold";
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.04em;
  color: #101010;
  cursor: pointer;
}
