.conteudos-container {
  zoom: 0.9;
  display: flex;
  background-color: #fefcfb;
  width: 100%;
  background-image: url("../images/bg-hoje-na-luta.png"),
    url("../images/bg-nucleo-cinza-claro.png");
  background-repeat: no-repeat, no-repeat;
  background-position-x: right, right;
  background-size: 90%, 35%;
}

.conteudos-content {
  z-index: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 94px;
  padding: 0 150px;
}

@media (max-width: 1200px) {
  .conteudos-content {
    padding: 0 80px;
  }
}

@media (max-width: 680px) {
  .conteudos-content {
    padding: 0 26px;
  }
}

.conteudos-titulo {
  font-family: "LifeMessy";
  font-weight: 100;
  font-size: 30px;
  margin: 25px 0;
  font-weight: normal;
}

.conteudos-hoje-na-luta-imgs {
  display: flex;
  flex-direction: row;
  margin-right: 17px;
  margin-bottom: 62px;
}

@media (max-width: 680px) {
  .conteudos-hoje-na-luta-imgs img:first-child {
    width: 160px;
    height: auto;
    margin-right: 10px;
  }

  .conteudos-hoje-na-luta-imgs img:last-child {
    width: 56px;
    height: auto;
  }
}

.conteudos-descricao * {
  font-size: 22px;
  font-family: "GalanoMedium";
}

.conteudos-descricao p:nth-child(1) {
  margin-bottom: 20px;
}

.conteudos-descricao p:nth-child(2) {
  margin-bottom: 38px;
}

.conteudos-nome-projeto {
  font-family: "GalanoBold";
}

.hilights-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 78px;
}

.hilight-cards {
  max-width: 270px;
  height: auto;
}

@media (max-width: 680px) {
  .hilights-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .hilight-cards {
    margin-bottom: 50px;
  }
}

@media (max-width: 1200px) {
  .hilight-cards {
    width: 200px;
    height: auto;
  }
}

@media (min-width: 1600px) {
  .hilights-container {
    justify-content: center;
    gap: 100px;
  }
}

.conteudos-playlists-titulo {
  font-family: "LifeMessy";
  font-weight: 100;
  font-size: 30px;
  margin-bottom: 44px;
}

.conteudos-playlists-section {
  display: flex;
  flex-direction: column;
}

.conteudos-playlist-botao {
  border: none;
  background: none;
  margin-bottom: 40px;
  cursor: pointer;
}

@media (max-width: 6800px) {
  .conteudos-playlist-botao {
    margin-bottom: 22px;
  }
}

.conteudos-playlist-img {
  width: 100%;
}

.conteudos-playlist-modal {
  z-index: 2;
  width: 50%;
  position: fixed;
  top: 25%;
  left: 25%;
  border-radius: 15px;
  background-color: #fefcfb;
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
  padding: 15px;
}

@media (max-width: 600px) {
  .conteudos-playlist-modal {
    left: 5%;
    width: 90%;
  }
}

.conteudos-playlist-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
}
.conteudos-playlist-modal-titulo {
  font-family: "LifeMessy";
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.conteudos-playlist-modal-fechar {
  border: none;
  background: none;
  cursor: pointer;
}
