.acesso-content {
  background-image: url("../images/bg-bambu.png");
  background-size: cover;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 95vh;
  padding-top: 90px;
  justify-content: center;
  align-items: center;
}

.acesso-container-wrapper {
}

.acesso-card {
  background-color: #fefcfb;
  width: 550px;
  border: 1px solid rgba(16, 16, 16, 0.25);
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px 50px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 720px) {
  .acesso-card {
    width: 96%;
  }
}

.acesso-h1-header {
  font-family: "LifeMessy";
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 40px;
  color: #101010;
  margin-bottom: 10px;
}

@media only screen and (max-width: 720px) {
  .acesso-h1-header {
    font-size: 22px;
    line-height: 30px;
  }
}

.acesso-button {
  display: flex;
  width: 200px;
  height: 48px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 24px;
  box-shadow: 4px 6px 6px 0px rgba(0, 0, 0, 0.3);
  font-family: "GalanoMedium";
  font-size: 16px;
}

.acesso-button-secondary {
  background-color: #fefcfb;
  color: #dd2626;
  margin-top: 8px;
  align-self: center;
}

.acesso-button:hover {
  scale: 105%;
  transition: 0.3s;
}

.acesso-img-header {
  margin: 20px 0;
  width: 300px;
}

.acesso-form {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  max-width: 600px;
}

.acesso-link-label {
  font-family: "GalanoMedium";
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: #101010;
  margin-left: 26px;
}

.acesso-button-primary {
  background-color: #dd2626;
  color: #fefcfb;
  margin: 12px 0;
  align-self: center;
}

.acesso-img-user {
  width: 16px;
  margin-right: 8px;
}

.acesso-error {
  color: #dd2626;
  font-family: "GalanoMedium";
  font-size: 16px;
}
