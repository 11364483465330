/* MOBILE: */

.ativacao-cadastro-container {
  display: flex;
  min-height: Calc(100vh - 94px) /* Substraindo altura do Header */;
  flex-direction: column;
  background-color: #fefcfb;
}

.ativacao-cadastro-content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  width: 100%;
  scroll-snap-type: y mandatory;
  overflow: hidden;
  margin: auto;
  max-width: 490px;
  z-index: 2;
}

.ativacao-cadastro-h1 {
  font-family: "LifeMessy";
  font-size: 40px;
  font-weight: 100;
  text-align: center;
  margin-top: 26px;
  letter-spacing: 0.06em;
}

.ativacao-cadastro-spinner-section {
  padding-left: 35vw;
}

.ativacao-cadastro-section {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  display: grid;
  min-width: 100%;
  justify-items: center;
  margin-top: 60px;
}

/* DESKTOP: */

@media only screen and (min-width: 680px) {
  .ativacao-cadastro-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    background-color: #fefcfb;
  }

  .ativacao-cadastro-content {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    min-width: 100%;
    min-height: 100%;
    scroll-snap-type: y mandatory;
    overflow: hidden;
    z-index: 2;
  }

  .ativacao-cadastro-section {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: grid;
    align-self: flex-start;
    padding-top: 240px;
    margin-left: 50%;
  }

  .ativacao-cadastro-h1 {
    font-family: "LifeMessy";
    font-size: 340%;
    font-weight: 100;
    text-align: center;
    margin-top: 0px;
  }

  .ativacao-cadastro-text {
    font-size: 150%;
  }

  .ativacao-cadastro-spinner-section {
    padding-top: 240px;
    padding-left: 50vw;
  }
}
