.header-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background-color: #fefcfb;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.header-wrapper {
  display: flex;
  width: 100%;
}

.header-wrapper-logo {
  display: flex;
  width: 150px;
  margin-left: 50px;
  justify-content: center;
  align-items: center;
}

.header-img-home {
  width: 90px;
}

.header-wrapper-search {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  margin-left: 32px;
  justify-content: center;
  align-items: center;
}

.header-search {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: flex;
  max-width: 320px;
  height: 54px;
  border: solid #fefcfb;
  border-radius: 60px;
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
  font-family: "GalanoMedium";
  font-size: 20px;
  padding-left: 24px;
  z-index: 1;
}

.header-img-search {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 40px;
  cursor: pointer;
  justify-self: end;
  margin-right: 14px;
  z-index: 2;
}

.header-wrapper-links {
  display: flex;
  width: 100%;
  column-gap: 60px;
  margin-right: 50px;
  margin-left: 50px;
  align-items: center;
}

@media only screen and (max-width: 1070px) {
  .header-wrapper-links {
    display: none;
  }
}

.header-wrapper-dropdown {
  position: relative;
  display: inline-block;
}

.header-dropdown-main {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  padding: 20px 0;
  color: #101010;
  font-family: "GalanoMedium";
  font-size: 20px;
}

.header-dropdown-main svg {
  margin: 0 8px 0 8px;
}

.header-dropdown-main:hover svg path {
  fill: #dd2626;
  transition: 0.3s;
}

.header-dropdown-main:hover {
  color: #dd2626;
  font-weight: bold;
  transition: 0.3s;
}

.header-dropdown-child {
  display: none;
  position: absolute;
  background-color: #fefcfb;
  min-width: 200px;
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
}

.header-dropdown-child-link {
  display: block;
  padding: 16px;
  color: #101010;
  font-family: "GalanoMedium";
  font-size: 16px;
  text-decoration: none;
}

.header-dropdown-child-link:hover {
  color: #dd2626;
  font-weight: bold;
  transition: 0.3s;
}

.header-dropdown-wrapper:hover .header-dropdown-child {
  display: block;
}

.header-link-static {
  color: #101010;
  font-family: "GalanoMedium";
  font-size: 20px;
  text-decoration: none;
}

.header-link-static:hover {
  color: #dd2626;
  font-weight: bold;
  transition: 0.3s;
}

.header-text-titulo {
  color: #101010;
  font-family: "GalanoMedium";
  font-weight: bold;
  font-size: 20px;
}

.header-wrapper-login {
  display: flex;
  column-gap: 10px;
  margin-right: 150px;
  align-items: center;
  justify-content: end;
}

@media only screen and (max-width: 1070px) {
  .header-wrapper-login {
    display: none;
  }
}

.header-button-login {
  display: flex;
  height: 48px;
  align-items: center;
  column-gap: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 28px;
  background-color: #101010;
  color: #fefcfb;
  font-family: "GalanoMedium";
  font-size: 20px;
  font-weight: 100;
}

.header-button-login:hover {
  scale: 105%;
  transition: 0.3s;
}

.header-button-img-user {
  width: 22px;
}

.header-img-user {
  width: 50px;
}

.header-wrapper-mobile {
  display: none;
}

@media only screen and (max-width: 1070px) {
  .header-wrapper-mobile {
    display: flex;
    margin-left: auto;
    padding: 28px 42px;
  }
}

.header-img-mobile {
  cursor: pointer;
  scale: 80%;
}
