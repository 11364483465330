.modal-body {
  display: flex;
  gap: 10px;
}

h2 {
  color: #101010;
  font-family: "LifeMessy";
  font-size: 30px;
  letter-spacing: 4px;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center;
}

p {
  font-family: "GalanoMedium";
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0.04em;
}

.button-permitir-acesso {
  display: flex;
  width: 200px;
  height: 48px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 24px;
  box-shadow: 4px 6px 6px 0px rgba(0, 0, 0, 0.3);
  font-family: "GalanoMedium";
  font-size: 16px;
  background-color: #dd2626;
  color: #fefcfb;
  margin: 12px 0;
  align-self: center;
}
