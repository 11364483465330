.agenda-wrapper-evento {
    display: flex;
    column-gap: 28px;
    align-items: center;
}

.agenda-wrapper-evento-off {
    display: none;
}

.agenda-wrapper-icone {
    display: grid;
    grid-template-columns: min-content;
    grid-template-rows: min-content;
}

.agenda-wrapper-img-icone {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: flex;
    position: relative;
    left: 74px;
    bottom: 16px;
    width: 40px;
    z-index: 2;
}

.agenda-wrapper-img-icone-off {
    display: none;
}

.agenda-wrapper-data-red {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
    min-width: 100px;
    min-height: 75px;
    border-radius: 15px;
    box-shadow: 6px 8px 8px 0px rgba(221, 38, 38, 1);
    font-family: "LifeMessy";
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.04em;
    color: #dd2626;
    text-align: center;
    justify-content: center;
    z-index: 1;
}

.agenda-wrapper-data {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
    min-width: 100px;
    min-height: 75px;
    border-radius: 15px;
    box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
    font-family: "LifeMessy";
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.04em;
    color: #dd2626;
    text-align: center;
    justify-content: center;
    z-index: 1;
}

.agenda-b-evento {
    font-family: "GalanoBold";
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 0.04em;
}

.agenda-b-evento-red {
    font-family: "GalanoBold";
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 0.04em;
    color: #dd2626;
}

.agenda-text-evento {
    font-family: "GalanoMedium";
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 0.04em;
}