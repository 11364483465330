* {
  margin: 0px;
  font-family: "Open Sans", sans-serif;
  font-family: "Galano Grotesque";
  box-sizing: border-box;
  text-decoration: none;
}

@font-face {
  font-family: "GalanoBold";
  src: local("galano-grotesque-bold"),
    url("./fonts/galano-grotesque-bold.otf") format("opentype");
}

@font-face {
  font-family: "GalanoMedium";
  src: local("galano-grotesque-medium"),
    url("./fonts/galano-grotesque-medium.otf") format("opentype");
}

@font-face {
  font-family: "LifeMessy";
  src: local("kg-life-is-messy"),
    url("./fonts/kg-life-is-messy.ttf") format("truetype");
}
