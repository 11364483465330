/* MOBILE: */

.errado-container {
  display: grid;
  min-height: 100vh;
  flex-direction: column;
  background-color: #fefcfb;
}

.errado-content {
  display: flex;
  scroll-snap-type: y mandatory;
  overflow: hidden;
  width: 100%;
  justify-self: flex-start;
}

.errado-section {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(5, 0.2fr);
  min-width: 100%;
  justify-items: center;
  margin-top: 60px;
}

.errado-img-formigas1 {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 100%;
  margin-top: 20px;
}

.errado-img-formigas2 {
  display: none;
}

.errado-h1-ops {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  font-family: "LifeMessy";
  font-size: 40px;
  font-weight: 100;
  text-align: center;
  margin-top: 26px;
  letter-spacing: 0.06em;
}

.errado-h1-algoerrado {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  font-family: "LifeMessy";
  font-size: 24px;
  font-weight: 100;
  text-align: center;
  letter-spacing: 0.06em;
}

.errado-h1-porfavor {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  padding: 40px;
  font-size: 18px;
  text-align: center;
  font-family: "GalanoMedium";
  font-weight: 100;
  margin-bottom: 30px;
  letter-spacing: 0.03em;
}

.errado-img-zebrada1 {
  grid-column: 1 / 2;
  grid-row: 5 / 6;
  margin-top: 16px;
  width: 100%;
}

.errado-img-zebrada2 {
  display: none;
}

.btn-wapper-errado {
  grid-column: 1 / 2;
  grid-row: 5 / 6;
  align-self: center;
}

.errado-img-ft1 {
  display: flex;
  margin-top: 20px;
  width: 100%;
  align-self: flex-end;
}

.errado-img-ft2 {
  display: none;
}

@media only screen and (min-width: 680px) {
  .errado-img-formigas1 {
    display: none;
  }

  .errado-img-formigas2 {
    display: flex;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    width: 100%;
    margin-top: 20px;
  }

  .errado-h1-ops {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    font-family: "LifeMessy";
    font-size: 320%;
    font-weight: 100;
    text-align: center;
    margin-top: 26px;
    letter-spacing: 0.06em;
  }

  .errado-h1-algoerrado {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    font-family: "LifeMessy";
    font-size: 220%;
    font-weight: 100;
    text-align: center;
    letter-spacing: 0.06em;
  }

  .errado-h1-porfavor {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    padding: 40px;
    font-size: 150%;
    text-align: center;
    font-family: "GalanoMedium";
    font-weight: 100;
    margin-bottom: 30px;
    letter-spacing: 0.03em;
  }

  .errado-img-zebrada1 {
    display: none;
  }

  .errado-img-zebrada2 {
    display: flex;
    grid-column: 1 / 2;
    grid-row: 5 / 6;
    margin-top: 16px;
    max-width: 100%;
  }

  .errado-img-ft1 {
    display: none;
  }

  .errado-img-ft2 {
    display: flex;
    margin-top: 20px;
    width: 100%;
    align-self: flex-end;
  }
}
