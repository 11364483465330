.google-drive-embed {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 75%;
}

.google-drive-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
}
