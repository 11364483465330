/* PÁGINA AULAS */

.aulas-container {
  height: 100%;
  min-height: 96vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../images/bg-lona2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.aulas-content {
  margin-top: 90px;
  flex: 1;
  padding: 20px 60px;
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

@media only screen and (max-width: 640px) {
  .aulas-content {
    padding: 16px;
    row-gap: 16px;
  }
}

.aulas-tab-nav {
  height: 40px;
  display: flex;
  align-items: center;
  column-gap: 32px;
}

.aulas-card {
  width: 100%;
  padding: 0;
  background-color: #fefcfb;
  border: 1px solid rgba(16, 16, 16, 0.25);
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 640px) {
  .aulas-wrapper-tabs {
    column-gap: 22px;
    justify-content: center;
  }
}

.aulas-text-tab-ativo {
  font-family: "GalanoMedium";
  font-size: 20px;
  font-weight: bold;
  line-height: 23px;
  color: #fefcfb;
  cursor: pointer;
}

.aulas-text-tab {
  font-family: "GalanoMedium";
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #fefcfb;
  cursor: pointer;
}

.aulas-inicial-container {
  display: flex;
}

.aulas-passo-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
  padding: 20px;
  row-gap: 20px;
}

@media only screen and (max-width: 1080px) {
  .aulas-passo-container {
    padding: 12px;
    margin-left: 0;
  }
}

.aulas-temporary-video-container {
  min-height: 360px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Visao Geral */

.aulas-inicio-wrapper-curso {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

@media only screen and (max-width: 640px) {
  .aulas-inicio-wrapper-curso {
    justify-content: space-around;
  }
}

h1.aulas-inicio-h1-curso {
  font-family: "GalanoBold";
  font-size: 32px;
  letter-spacing: 0.1em;
  color: #101010;
}

@media only screen and (max-width: 640px) {
  h1.aulas-inicio-h1-curso {
    font-size: 24px;
  }
}

h2.aulas-inicio-h2-curso {
  font-family: "Galano Grotesque";
  font-size: 20px;
  letter-spacing: 0.04em;
  color: #101010;
}

@media only screen and (max-width: 640px) {
  h2.aulas-inicio-h2-curso {
    font-size: 18px;
  }
}

.aulas-inicio-button-continuar {
  display: flex;
  width: 218px;
  height: 48px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 28px;
  box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: #dd2626;
  color: #fefcfb;
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: 100;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 640px) {
  .aulas-inicio-button-continuar {
    align-self: center;
  }
}

.aulas-inicio-button-continuar:hover {
  scale: 105%;
  transition: 0.3s;
}

.aulas-inicio-img-bg1 {
  width: 340px;
  align-self: flex-end;
  justify-self: flex-end;
}

@media only screen and (max-width: 1160px) {
  .aulas-inicio-img-bg1 {
    width: 300px;
  }
}

@media only screen and (max-width: 640px) {
  .aulas-inicio-img-bg1 {
    display: none;
  }
}

/* PAINEL CURSO */

.aulas-video-container-conteudo {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  width: 100%;
  height: 100%;
}
.aulas-video-img-salvo {
  display: none;
}

.aulas-video-wrapper-player {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.aulas-video-wrapper-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aulas-material-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.aulas-material-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aulas-wrapper-btn {
  display: flex;
  column-gap: 18px;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.aulas-button-next-prev {
  display: flex;
  width: 120px;
  height: 42px;
  column-gap: 10px;
  cursor: pointer;
  border: none;
  border-radius: 28px;
  box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: #fefcfb;
  color: #101010;
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: 100;
  align-items: center;
  justify-content: center;
}

.aulas-button-next-prev:hover {
  scale: 105%;
  transition: 0.3s;
}

.aulas-video-img-seta {
  width: 12px;
  height: 22px;
}

.aulas-passo-header {
  display: flex;
  height: 70px;
}

.aulas-passo-header-container {
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
  justify-content: flex-start;
}

.titulo-da-aula {
  font-family: GalanoBold;
  text-align: left;
  font-size: 18px;
  letter-spacing: 2.2px;
  text-transform: uppercase;
  margin: 0;
}

.subtitulo-da-aula {
  color: #101010;
  font-family: GalanoMedium;
  font-size: 14px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .titulo-da-aula {
    font-size: 16px;
  }
  .subtitulo-da-aula {
    display: none;
  }
}

/* POP-UP DE CONFIRMAÇÃO */

.aulas-popup-container {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 24px;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.aulas-popup-container-off {
  display: none;
}

.aulas-popup-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  row-gap: 32px;
  border-radius: 15px;
  background-color: #fefcfb;
  box-shadow: 6px 8px 8px rgba(0, 0, 0, 0.25);
  padding: 24px;
  align-items: center;
  justify-content: center;
}

.aulas-popup-text {
  font-family: "GalanoMedium";
  font-size: 24px;
  font-weight: bold;
  line-height: 23px;
  color: #101010;
}

.aulas-popup-button {
  display: flex;
  width: 120px;
  height: 48px;
  column-gap: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 28px;
  box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: #dd2626;
  color: #fefcfb;
  font-family: "GalanoMedium";
  font-size: 18px;
  font-weight: 100;
  align-items: center;
  justify-content: center;
}

.aulas-popup-button:hover {
  scale: 105%;
  transition: 0.3s;
}
