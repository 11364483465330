.buttonzap-wrapper {
    display: grid;
    grid-template-columns: min-content;
    grid-template-rows: min-content;
    width: 186px;
    height: 96px;
    align-content: flex-end;
}

.buttonzap-wrapper:hover .buttonzap-img-megafone {
    scale: 105%;
    transform: rotate(-10.36deg);
    transition: 0.3s;
}

.buttonzap-link {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: flex;
    width: 186px;
    height: 48px;
    align-items: center;
    column-gap: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 28px;
    box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.5);
    background-color: #95b44e;
    color: #fefcfb;
    font-family: "GalanoMedium";
    font-size: 18px;
    font-weight: 100;
    justify-content: center;
    z-index: 1;
}

.buttonzap-img-megafone {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    position: relative;
    left: 114px;
    bottom: 40px;
    width: 78px;
    height: 56px;
    z-index: 2;
}